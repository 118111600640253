import { AuthenticationService } from '@/app/auth/services/authentication.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IonModal } from '@ionic/angular';
import { first } from 'rxjs';
import { ConfirmModalService } from '../confirm-modal.service';

import { Logger } from '@/app/services/logger.service';
const log = new Logger('LoginModalComponent');

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit {
  @Input() isLogIn: boolean = false;
  @ViewChild(IonModal) modal!: IonModal;

  cancel() {
    this.modal.dismiss(null, 'cancel');
  }

  confirm() {
    this.modal.dismiss(true, 'confirm');
  }

  onWillDismiss(event: any) {
    const ev = event;
    if (ev.detail.role === 'confirm') {

    }


  }


  public loginForm!: FormGroup;
  public loading = false;
  passwordTextType = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _confirmModalService: ConfirmModalService,
  ) { }

  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      username: ["", [Validators.required]],
      password: ["", Validators.required],
    });
  }
  get f(): any {
    return this.loginForm.controls;
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }

    // Login
    this.loading = true;

    this._authenticationService
      .login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        (data) => {
          // this._router.navigate(['tabs']);
          this.confirm()
        },
        (error) => {
          log.error('error sign in', error);
          this._confirmModalService.ok('ไม่สามารถเข้าสู่ระบบได้', error.error.message)
          this.loading = false;
        }
      );
  }

  goRegister() {
    this._router.navigate(['sign-up']);
  }

  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType
  }
}


