import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { CommentModalComponent } from '../comment-modal/comment-modal.component';
import { AssessmentModalComponent } from '../assessment-modal/assessment-modal.component';
import { SignatureModalComponent } from '../signature-modal/signature-modal.component';
import { ReportMaintenanceModalComponent } from '../report-maintenance-modal/report-maintenance-modal.component';
import { ReportMaintenanceRepairModalComponent } from '../report-maintenance-repair-modal/report-maintenance-repair-modal.component';
import { SignatureType } from '@/app/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ModalServiceService {
  public commentModal: any;
  public assessmentModal: any;
  public signatureModal: any;
  public reportMaintenanceModal: any;
  public reportMaintenanceRepairModal: any;



  public dataComment$ = new Subject<any>();
  public dataAssessment$ = new Subject<any>();


  constructor(
    private modalController: ModalController,
  ) {


  }


  async comment(dataTemp: any): Promise<any> {
    this.commentModal = await this.modalController.create({
      component: CommentModalComponent,
      cssClass: ['modal-comment'],
      componentProps: {
        text: dataTemp?.text || null,
        comment: dataTemp?.comment || null
      },
    });
    this.dataComment$.next(dataTemp);
    await this.commentModal.present();
    const { data } = await this.commentModal.onWillDismiss();
    return data;
  }
  sentData(data: any) {
    this.commentModal.dismiss(data)
  }

  async assessment(dataTemp: any): Promise<any> {
    this.assessmentModal = await this.modalController.create({
      component: AssessmentModalComponent,
      cssClass: ['modal-assessment'],
      componentProps: {
        template: dataTemp
      },
    });
    await this.assessmentModal.present();
    const { data } = await this.assessmentModal.onWillDismiss();
    return data;
  }

  assessmentConfirm(data: any) {
    this.assessmentModal.dismiss(data)
  }

  async signature(type: 'CLEAN' | 'REPAIR' = 'CLEAN') {

    this.signatureModal = await this.modalController.create({
      component: SignatureModalComponent,
      cssClass: ['modal-signature'],
      componentProps: {
        type
      },
      backdropDismiss: false
    });
    await this.signatureModal.present();
    const { data } = await this.signatureModal.onWillDismiss();
    return data;
  }

  signatureClose(data: { image: any, signatureType: SignatureType } | null) {

    const dataTemp = data ? {
      image: data?.image || null,
      signatureType: data?.signatureType
    } : null
    this.signatureModal.dismiss(dataTemp);
  }




  // modal report 
  async maintenanceReport(maintenanceId: string) {

    this.reportMaintenanceModal = await this.modalController.create({
      component: ReportMaintenanceModalComponent,
      cssClass: ['modal-report'],
      componentProps: {
        maintenanceId
      },
      backdropDismiss: false
    });
    await this.reportMaintenanceModal.present();
    const { data } = await this.reportMaintenanceModal.onWillDismiss();
    return data;

  }
  async maintenanceReportRepair(maintenanceRepairId: string) {

    this.reportMaintenanceRepairModal = await this.modalController.create({
      component: ReportMaintenanceRepairModalComponent,
      cssClass: ['modal-report'],
      componentProps: {
        maintenanceRepairId
      },
      backdropDismiss: false
    });
    await this.reportMaintenanceRepairModal.present();
    const { data } = await this.reportMaintenanceRepairModal.onWillDismiss();
    return data;

  }

  maintenanceReportClose() {
    this.reportMaintenanceModal.dismiss(null);
  }
  maintenanceReportRepairClose() {
    this.reportMaintenanceRepairModal.dismiss(null);
  }





}
