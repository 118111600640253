<ion-header>
  <ion-toolbar>
    <ion-title> แสดงความคิดเห็น </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding comment-modal">
  <!-- <ion-list>
    <ion-item>
      <label>เลือกความเห็น</label>
      <ion-select aria-label="fruit" placeholder="Select fruit">
        <ion-select-option value="apples">Apples</ion-select-option>
        <ion-select-option value="oranges">Oranges</ion-select-option>
        <ion-select-option value="bananas">Bananas</ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-textarea
        placeholder="แสดงความคิดเห็น"
        [autoGrow]="true"
        [(ngModel)]="textComment"
      >
      </ion-textarea>
    </ion-item>
  </ion-list> -->

  <form>
    <div class="form-group">
      <label for="exampleFormControlSelect1">ความเห็นของช่าง</label>

      <ion-select
        justify="space-between"
        aria-label="comment"
        placeholder="เลือกความเห็น"
        class="select-comment"
        id="exampleFormControlSelect1"
        [(ngModel)]="comment"
        [ngModelOptions]="{ standalone: true }"
      >
        <ion-select-option value="สภาพปกติ"
          >สภาพปกติ</ion-select-option
        >
        <ion-select-option value="แอร์เสื่อมสภาพ"
          >แอร์เสื่อมสภาพ</ion-select-option
        >
        <ion-select-option value="แอร์มีอายุการใช้งานเกิน 5-7 ปี"
          >แอร์มีอายุการใช้งานเกิน 5-7 ปี</ion-select-option
        >
        <ion-select-option value="อุปกรณ์ภายนอกเสื่อมสภาพ"
          >อุปกรณ์ภายนอกเสื่อมสภาพ</ion-select-option
        >
        <ion-select-option value="อุปกรณ์ภายในเสื่อมสภาพ"
          >อุปกรณ์ภายในเสื่อมสภาพ</ion-select-option
        >
        <ion-select-option value="อื่นๆ"
          >อื่นๆ</ion-select-option
        >
      </ion-select>
    </div>
    <div
      class="form-group"
      *ngIf="
        comment === 'อุปกรณ์ภายในเสื่อมสภาพ' ||
        comment === 'อุปกรณ์ภายนอกเสื่อมสภาพ' ||
        comment === 'อื่นๆ'
      "
    >
      <label for="comment-detail">ระบุรายละเอียด</label>
      <textarea
        class="form-control"
        id="comment-detail"
        rows="3"
        [(ngModel)]="text"
        [ngModelOptions]="{ standalone: true }"
      ></textarea>
    </div>
  </form>
</ion-content>
<ion-footer>
  <ion-toolbar class="ion-padding">
    <ion-button expand="block" (click)="submitComment()">
      ยืนยันความคิดเห็น</ion-button
    >
    <ion-button expand="block" fill="outline" (click)="closeComment()">
      ยกเลิก</ion-button
    >
  </ion-toolbar>
</ion-footer>
