<h4 class="title">Contact Our Service</h4>
<div class="container">
  <ion-row>
    <ion-col size="2">
      <ion-icon name="location-outline"></ion-icon>
    </ion-col>
    <ion-col size="10">
      <p>
        TECHNICAL WATER COMPANY LIMITED เลขที่ 301/856 ซอยรามคำแหง 68
        ถนนรามคำเเหง แขวงหัวหมาก เขตบางกะปิ กรุงเทพมหานคร 10240
      </p>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="2">
      <ion-icon name="call-outline"></ion-icon>
    </ion-col>
    <ion-col size="10">
      <p>02-735-3022</p>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="2">
      <ion-icon name="mail-outline"></ion-icon>
    </ion-col>
    <ion-col size="10">
      <p>Technicalwater2015@gmail.com</p>
    </ion-col>
  </ion-row>
</div>
