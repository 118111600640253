import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  public notShowAlert$ = new Subject<any>();

  private spinner: any = {
    spinner: 'circles',
    duration: 2000,
    translucent: true,
    cssClass: 'custom-class custom-loadingV1',
    message: 'Loading Data'
  };
  private spinnerLong: any = {
    spinner: 'circles',
    duration: 20000,
    translucent: true,
    cssClass: 'custom-class custom-loadingV1',
    message: 'Loading Data'
  };
  private noMsgSpinner: any = {
    spinner: 'circles',
    duration: 2000,
    translucent: true,
    cssClass: 'custom-class custom-loadingV1',
  };


  private loading: any;

  constructor(public loadingController: LoadingController) {
    this.notShowAlert$.subscribe(e => {
      if (this.loading) {
        this.loading.dismiss();
      }
    })
  }


  async show() {
    this.loading = null;

    if (!this.loading) {
      this.loading = await this.loadingController.create(this.spinner);
      this.loading.present();
    }
  }

  async showLong(message?: string) {
    if (this.loading) {
      this.loading = null;
    }
    if(message) this.spinnerLong.message = message;
    this.loading = await this.loadingController.create(this.spinnerLong);
    this.loading.present();
  }

  async showOption(duration = 2000, message = 'กำลังทำรายการ') {
    this.loading = null;
    const option: any = {
      spinner: 'circles',
      duration,
      translucent: true,
      cssClass: 'custom-class custom-loadingV1',
      message
    };
    if (!this.loading) {
      this.loading = await this.loadingController.create(option);
      this.loading.present();
    }
  }

  //Hide loading
  async hide() {
    if (this.loading) {
      this.loading.dismiss();
      this.loading = null;
    }
  }

  //show No Msg
  async showNoMsg() {
    this.loading = null;
    if (!this.loading) {
      this.loading = await this.loadingController.create(this.noMsgSpinner);
      this.loading.present();
    }
  }

}
