import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() showBtn2: boolean = false;

  @Input() textBtn: string = 'ตกลง';
  @Input() textBtn2: string = 'ตกลง';

  @Input() btnDisabled = false;
  @Output() onClick = new EventEmitter();
  @Output() onClick2 = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  click() {
    this.onClick.emit();
  }
  click2() { 
    this.onClick2.emit();
  }



}
