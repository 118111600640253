import { ApiMaintenanceService } from '@/app/services/api-maintenance.service';
import { CheckAssetService } from '@/app/services/check-asset.service';
import { Component, Input, OnInit } from '@angular/core';
import { ModalServiceService } from '../modal-services/modal-service.service';
import { DatePipe } from '@angular/common';
import { LoadingService } from '@/app/services/loading.service';

@Component({
  selector: 'app-report-maintenance-repair-modal',
  templateUrl: './report-maintenance-repair-modal.component.html',
  styleUrls: ['./report-maintenance-repair-modal.component.scss'],
})
export class ReportMaintenanceRepairModalComponent implements OnInit {
  @Input() maintenanceRepairId: any;
  public repair: any;

  constructor(
    private _modal: ModalServiceService,
    private maintenanceService: ApiMaintenanceService,
    private _loadingService: LoadingService,

  ) { }

  ngOnInit() {

    this.fetchData();
  }




  close() {
    this._modal.maintenanceReportRepairClose();
  }


  async fetchData() {
    this._loadingService.show()

    try {
      const results: any = await this.maintenanceService.getMaintenanceRepairById(this.maintenanceRepairId);
      if (results) {
        this.repair = results;
      }
      console.log('repair', this.repair);
    } catch (error) {
      console.error('fetch data error: ', error);
      throw error;
    } finally {
      this._loadingService.hide()
    }
  }

  dateTh(value: any) {
    if (!value) return '-'
    const datePipe = new DatePipe('en-TH');
    return datePipe.transform(value, 'dd/MM/yyyy HH:mm') + ' น.';
  }

}
