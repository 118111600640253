import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { ModalServiceService } from '../modal-services/modal-service.service';
import { ConfirmModalService } from '../confirm-modal.service';
@Component({
  selector: 'app-comment-modal',
  templateUrl: './comment-modal.component.html',
  styleUrls: ['./comment-modal.component.scss'],
})
export class CommentModalComponent implements OnInit {
  @ViewChild('select') select: any;
  @Input() comment!: string;
  @Input() text!: string;
  constructor(
    private _confirmModalService: ConfirmModalService,
    private _modal: ModalServiceService
  ) {
    this._modal.dataComment$.subscribe((data) => {
      if (data) {
        this.comment = data.comment;
        this.text = data.text;
      }
    })
  }

  ngOnInit() {


  }

  ngAfterContentInit() {
    this.selectE();
  }

  async submitComment() {
    if (await this._confirmModalService.confirm('ยืนยันความคิดเห็น และยืนยันการส่งแบบฟอร์ม') !== 'confirm') {

      return;
    }
    const data = {
      comment: this.comment || '',
      text: this.comment === 'อุปกรณ์ภายในเสื่อมสภาพ' ? this.text : this.comment === 'อุปกรณ์ภายนอกเสื่อมสภาพ' ? this.text : this.comment === 'อื่นๆ' ? this.text : '' || ''
    }
    console.log('data comment modal',data)
    this._modal.sentData(data)
  }

  async closeComment() {

    if (await this._confirmModalService.confirm('ยกเลิกการส่งฟอร์มล้างแอร์') !== 'confirm') {
      return;
    }

    this._modal.sentData(null)
  }

  selectE() {
    let doc = document.querySelector('.select-comment');
    // let sha = doc?.shadowRoot
    // console.log('ho',doc,sha)

    if (doc) {
      let shadowRoot = doc.shadowRoot;
      if (!shadowRoot) {
        shadowRoot = doc.attachShadow({ mode: 'open' });
      }
      // let label = document.createElement('label');

      // label.textContent = 'Example Label';
      // label.classList.add('something');

      // shadowRoot.appendChild(label);
      let selectWrapper = shadowRoot.querySelector('.select-wrapper')

      if (selectWrapper) {

      } else {
        // Element with class '.select-wrapper' not found within the shadow root
      }



      // Append the <label> element to the shadow root
    } else {
      // Handle the case when the element is not found
    }

    // document.querySelector('#my-select').shadowRoot.querySelector('.select-icon-inner').setAttribute('style', 'opacity:1');
  }

}
