import { Injectable } from '@angular/core';
import { assetType, dataMaintenanceType } from '../data';
@Injectable({
  providedIn: 'root'
})
export class CheckAssetService {
  assetType = assetType;
  maintenanceType = dataMaintenanceType;

  groupTypeAir = [
    'AIRCONDITION',
    'AIRCONDITION_FCU',
    'AIRCONDITION_AHU'
  ]

  constructor() { }

  checkAssetTypeAir(type: string): boolean {
    return this.groupTypeAir.some((e) => e === type);
  }

   pipeAssetTypeForName(type: string): string | null {
    const match = this.assetType.find(asset => asset.value === type);
    return match ? match.name : null;
  }


  pipeMaintenanceTypeForName(type: string): string | null {
    const match = this.maintenanceType.find(maintenance => maintenance.value === type);
    return match ? match.name : null;
}




}
