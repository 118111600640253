import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule, Routes } from '@angular/router';
import { LayoutModule } from 'src/app/layout/layout.module';
import { ComponentsModule } from 'src/app/components/components.module';

import { SignInComponent } from './pages/sign-in/sign-in.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { LoginWithAssetTokenComponent } from './pages/login-with-asset-token/login-with-asset-token.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        // RouterModule.forChild(routes),
        LayoutModule,
        ComponentsModule,
        ReactiveFormsModule
    ],
    exports: [SignUpComponent, SignInComponent, LoginWithAssetTokenComponent],
    declarations: [SignUpComponent, SignInComponent, LoginWithAssetTokenComponent]
})
export class AuthModule { }
