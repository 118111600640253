import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HeaderComponent } from './components/header/header.component';
import { HeaderProfileComponent } from './components/header-profile/header-profile.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
    declarations: [
        HeaderComponent,
        HeaderProfileComponent,
        FooterComponent
    ],
    imports: [
        CommonModule,
        IonicModule.forRoot(),
        ReactiveFormsModule,
        FormsModule
    ],
    exports: [
        HeaderComponent,
        HeaderProfileComponent,
        FooterComponent
    ]
})
export class LayoutModule { }