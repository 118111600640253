import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';

import { Logger } from 'src/app/services/logger.service';
import { ConfirmModalService } from 'src/app/components/modals/confirm-modal.service';

const log = new Logger('AuthGuard')
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _router: Router,
    private _confirmModalService: ConfirmModalService,
    private _authenticationService: AuthenticationService) { }

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this._authenticationService.currentUserValue;
    const currentToken = this._authenticationService.currentTokenValue;
    if (currentToken && currentUser) {
      const isTokenExp = this.tokenExpired(currentToken.refreshToken)

      if (!isTokenExp) {

        this._confirmModalService.ok('Token Expired!!!', 'Login Again').then(() => {
          this._authenticationService.logout();
          this._router.navigate(['sign-in']);
        })
        return false;
      }
      return true;

    }
    this._router.navigate(['sign-in']);
    return false;
  }

  tokenExpired(refreshToken: string) {
    const expiry = (JSON.parse(atob(refreshToken.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) < expiry;
  }
}
