<h4 *ngIf="!isToggle" class="title">{{ textTitle }}</h4>
<div *ngIf="isToggle" class="con-title">
  <h4 class="title">{{ textTitle }}</h4>
  <p (click)="onClick1()">
    {{ showDetail ? "ซ่อนรายละเอียด" : "โชว์รายละเอียด" }}
  </p>
</div>
<div class="container" [hidden]="!showDetail">
  <ng-content></ng-content>
</div>
