<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button [hidden]="true">
        <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title> </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding report-modal">
  <div class="a4-page">
    <div class="form-repair">
      <div class="twmss-header">
        <img class="repair-logo" src="/assets/logo/logo_twmss.png" alt="" />
        <div class="repair-text">
          <h3>บริษัท เทคนิคอล วอเตอร์ จำกัด</h3>
          <h3>TECHNICAL WATER CO., LTD</h3>
          <p>
            301/856 ซ.รามคำแหง 68 ถ.รามคำเเหง แขวงหัวหมาก เขตบางกะปิ กรุงเทพฯ
            10240 <br />
            หมายเลขโทรศัพท์ (Tel). 02-735-3022, Email:
            Technicalwater2015@gmail.com
          </p>
        </div>
      </div>
      <hr />
      <div class="repair-header">
        <div class="text-header">
          <h2>ใบรายงานการซ่อม</h2>
        </div>
      </div>
      <div class="place mt-3">
        โรงพยาบาล : <small>{{ repair?.asset?.place?.name }}</small>
      </div>
      <div class="notification-date">
        วันที่รับแจ้ง :
        <small>{{ dateTh(repair?.notificationDate) || "-" }}</small>
      </div>
      <div class="breakdown-data-place d-flex">
        <div class="text-header">อาการเสีย :</div>
        <div class="text-detail" style="padding-left: 4px">
          <small *ngIf="repair?.errorTitle !== 'อื่นๆ'">{{
            repair?.errorTitle || "-"
          }}</small>
          <small *ngIf="repair?.errorText">{{
            repair?.errorText || "-"
          }}</small>
        </div>
      </div>
      <div class="work-details">
        เวลาเข้าตรวจสอบ :
        <small>{{ dateTh(repair?.dateCheck) || "-" }}</small>
      </div>
      <hr />
      <div class="edit-details mt-1">รายละเอียดการแก้ไข :</div>
      <div class="text-edit-details mt-1">
        <div class="text-ed">
          <small>{{ repair?.detailError || "-" }}</small>
        </div>
      </div>
      <hr style="margin: 2rem 0 2rem 0" />
      <div class="checkbox d-flex">
        <div *ngIf="repair?.repairFinish === 'SUCCESS'">
          <ion-icon name="checkbox-outline"></ion-icon>
          <span class="ml-1 mr-1">ดำเนินการแล้วเสร็จ</span>
        </div>
        <div *ngIf="repair?.repairFinish === 'UNSUCCESSFUL'">
          <ion-icon name="square-outline"></ion-icon>
          <span class="ml-1 mr-1">ดำเนินการแล้วเสร็จ</span>
        </div>
        <div *ngIf="repair?.repairFinish === 'SUCCESS'">
          <ion-icon name="square-outline"></ion-icon>
          <span class="ml-1">ดำเนินการยังไม่แล้วเสร็จ</span>
        </div>
        <div *ngIf="repair?.repairFinish === 'UNSUCCESSFUL'">
          <ion-icon name="checkbox-outline"></ion-icon>
          <span class="ml-1">ดำเนินการยังไม่แล้วเสร็จ</span>
        </div>
      </div>
      <div class="note mt-1">
        หมายเหตุ : <small>{{ repair?.repairFinishText || "-" }}</small>
      </div>
    </div>
    <table class="table-comment mt-2">
      <tbody>
        <tr>
          <td>ความเห็นของลูกค้า: </td>
          <td>
            ความเห็นของทีมช่าง:
            <small>{{
              repair?.comments ? repair?.comments[0]?.comment : "" || "-"
            }}</small>
          </td>
          <td>
            <div class="text-center">วันที่ดำเนินการแก้ไขแล้วเสร็จ</div>
          </td>
        </tr>
        <tr>
          <td>-</td>
          <td>
            <small>{{
              repair?.comments ? repair?.comments[0]?.text : "" || "-"
            }}</small>
          </td>
          <td>
            วันที่: <small>{{ dateTh(repair?.endDate) || "-" }}</small>
          </td>
        </tr>
        <tr class="tr-signature">
          <td class="con-signature">
            <div class="signature">
              <span>ลงชื่อผู้แจ้งซ่อม:</span>
              <img
                *ngIf="repair?.MaintenanceSignature?.length"
                [src]="repair?.MaintenanceSignature[0]?.signatureImage"
                alt="signature"
              />
            </div>
          </td>
          <td>
            ลงชื่อทีมช่าง:
            <small>{{
              repair?.user?.firstname && repair?.user?.lastname
                ? repair?.user?.firstname + " " + repair?.user?.lastname
                : "-"
            }}</small>
          </td>
          <td>
            <div class="text-center">เวลาดำเนินการแล้วเสร็จ</div>
          </td>
        </tr>
        <tr>
          <td>
            วันที่:
            <span>
              {{
                repair?.MaintenanceSignature?.length
                  ? dateTh(
                      repair?.MaintenanceSignature
                        ? repair?.MaintenanceSignature[0]?.createdAt
                        : null
                    )
                  : null
              }}</span
            >
          </td>
          <td>
            วันที่:
            <small>{{
              dateTh(
                repair?.comments ? repair?.comments[0]?.commentDate : null
              ) || "-"
            }}</small>
          </td>
          <td>
            ระยะเวลา <small>{{ repair?.amountDateFinish || 0 }} วัน</small>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ion-content>
