import * as Sentry from '@sentry/angular-ivy';
import { environment } from 'src/environments/environment';

export const initSentry = () => {
  if (environment.sentry.enable) {
    Sentry.init({
      dsn: environment.sentry.dsn,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
        new Sentry.Replay(),
      ],
      tracesSampleRate: environment.sentry.tracesSampleRate,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
};
