<app-header
  title="กรุณาลงชื่อเข้าใช้"
  [hideIconLeft]="false"
  [hideIconRight]="false"
></app-header>

<ion-content class="ion-padding">

  <ion-grid>
    <ion-row class="ion-padding">
      <ion-col  class="ion-text-center">
        <img src="/assets/logo/logo_twmss.png" alt="logo" />
      </ion-col>
    </ion-row>
  </ion-grid>


  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <!-- <label for="exampleInputEmail1">Email</label>
      <input
        formControlName="email"
        type="email"
        class="form-control"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        placeholder="Enter email"
      /> -->
      <label for="username">Username</label>
      <input
        formControlName="username"
        type="text"
        class="form-control"
        id="username"
        aria-describedby="emailHelp"
        placeholder="Enter username"
      />

    </div>
    <div class="form-group">
      <label for="exampleInputPassword1">Password</label>
      <div class="input-group input-group-merge form-password-toggle">
        <input
          formControlName="password"
          [type]="passwordTextType ? 'text' : 'password'"
          class="form-control"
          id="exampleInputPassword1"
          placeholder="Password"
        />
        <div class="input-group-append">
          <span class="input-group-text cursor-pointer" (click)="togglePasswordTextType()">
            <ion-icon
              [name]="passwordTextType ? 'eye-outline' : 'eye-off-outline'"
            ></ion-icon>
          </span>
        </div>
      </div>
    </div>
    <!-- <div class="form-group mb-4">
      <p style="text-align: end" (click)="goRegistor()">ลงทะเบียน</p>
    </div> -->
    <ion-button class="mt-1" type="submit" expand="block" [disabled]="isLoading">
      ลงชื่อเข้าใช้
    </ion-button>
  </form>

</ion-content>
