import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-card',
  templateUrl: './menu-card.component.html',
  styleUrls: ['./menu-card.component.scss'],
})
export class MenuCardComponent implements OnInit {
  @Input() title: string | undefined;
  @Input() detail: string | undefined;
  @Input() iconColor: string = '#5ce1cd';
  @Input() backgroundColor: string = '#d8f3f1';
  @Input() iconName: string = 'image';
  constructor() { }

  ngOnInit() { }

}
