<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="goBack()" [hidden]="hideIconLeft">
        <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title >{{ title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="goScan(hideIconRight)"  >
        <ion-icon [hidden]="hideIconRight" slot="icon-only" name="scan"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
