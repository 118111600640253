
export const dataMaintenanceType = [
    {
        name: 'ล้างใหญ่',
        value: 'TS',
        requireAnswer: true,
    },
    {
        name: 'ล้างย่อย',
        value: 'T2M',
        requireAnswer: false,

    },
    {
        name: 'ล้างพัดลม',
        value: 'FAN',
        requireAnswer: false,
    },
    {
        name: 'Generator',
        value: 'GENERATOR',
        requireAnswer: false,
    },
    {
        name: 'อื่นๆ',
        value: 'OTHER',
        requireAnswer: false,
    }
]

