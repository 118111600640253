import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Logger } from './logger.service';
import { CheckIn } from '../interfaces';
const log = new Logger('ApiService');

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private httpClient: HttpClient,
  ) { }



  getAssetsById(identifierNumber: string): Promise<any> {
    const url = `${environment.apiUrl}/mobile-platform/assets/${identifierNumber}`;
    let params = new HttpParams();
    return this.httpClient
      .get(url, { params })
      .toPromise()
      .then((response) => response);
  }

  getAssetsByIdentifierNumber(identifierNumber: string): Promise<any> {

    const url = `${environment.apiUrl}/mobile-platform/assets/identifierNumber`;
    let params = new HttpParams();
    return this.httpClient
      .post(url, { identifierNumber: identifierNumber })
      .toPromise()
      .then((response) => response);
  }

  getPlace(): Promise<any> {
    const url = `${environment.apiUrl}/mobile-platform/places`;
    let params = new HttpParams()
      .set('limit', '100')
      .set('page', '1');
    return this.httpClient
      .get(url, { params })
      .toPromise()
      .then((response) => response);
  }

  updateProfile({ firstname = '', lastname = '', mobilePhone = '', email = '' }, picture: File): Promise<any> {
    const data = {
      "firstname": firstname, "lastname": lastname, "mobilePhone": mobilePhone, "email": email
    }
    log.debug('data update', data)
    return this.httpClient
      .patch(`${environment.apiUrl}/mobile-platform/me`, data)
      .toPromise()
      .then((response) => response);
  }



  // maps check in 

  checkIn(dataTemp: CheckIn): Promise<any> {

    log.debug('check in', dataTemp)
    return this.httpClient
      .post(`${environment.apiUrl}/users/check-in`, dataTemp)
      .toPromise()
      .then((response) => response);
  }

  getUserCheckIn(userId: string): Promise<any> {
    const url = `${environment.apiUrl}/mobile-platform/check-in-users`;
    let params = new HttpParams()
      .set('userId', userId)
      .set('limit', '5')
      .set('page', '1')
      .set('orderBy', 'date:desc')
    return this.httpClient
      .get(url, { params })
      .toPromise()
      .then((response) => response);
  }


  getAssessment({ groupId = '', status = 'nocomment', limit = 10 }): Promise<any> {
    // status = comment | nocomment
    const url = `${environment.apiUrl}/mobile-platform/group-assets/maintenances`;
    let params = new HttpParams()
      .set('userId', groupId)
      .set('status', status)
      .set('limit', limit.toString())
      .set('page', '1')
      .set('orderBy', 'date:desc')
    return this.httpClient
      .get(url, { params })
      .toPromise()
      .then((response) => response);
  }


  getFloorByID(floorId: string) {
    const url = `${environment.apiUrl}/mobile-platform/floors/${floorId}`;
    let params = new HttpParams()
    return this.httpClient
      .get(url, { params })
      .toPromise()
      .then((response) => response);

  }

}
