import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-maintenance-card',
  templateUrl: './maintenance-card.component.html',
  styleUrls: ['./maintenance-card.component.scss'],
})
export class MaintenanceCardComponent implements OnInit {

  @Input() date!: string;
  @Input() name!: string;
  @Input() place!: string;
  @Input() building!: string;
  @Input() floor!: string;
  @Input() typeAsset!: string;


  @Output() onClickBtn = new EventEmitter<any>()
  @Output() onClickBtnReport = new EventEmitter<any>()


  constructor(

  ) { }

  ngOnInit() {
  }


  clickBtn() {
    this.onClickBtn.emit()
  }

  openReport() {
    this.onClickBtnReport.emit()
  }



}
