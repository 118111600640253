import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConfirmModalService } from '../confirm-modal.service';
import { ModalServiceService } from '../modal-services/modal-service.service';
import { Logger } from '@/app/services/logger.service';
import { FormBuilder, Validators, FormGroup, FormArray, FormControl } from '@angular/forms';
import { Answer, AssessmentAnswer } from '@/app/interfaces/assessment';


const log = new Logger('AssessmentModalComponent')
@Component({
  selector: 'app-assessment-modal',
  templateUrl: './assessment-modal.component.html',
  styleUrls: ['./assessment-modal.component.scss'],
})
export class AssessmentModalComponent implements OnInit {

  @Input() username: string = '';
  @Input() comments!: string;
  @Input() status!: string;
  @Input() template: any;
  @Input() satisfactionSurveyQuestion: any[] | undefined;
  @Input() questions = <any>[];
  @Input() dataWatch: any;

  questionForm!: FormGroup;
  // tableForm!: FormGroup;

  choices = [
    { label: '5', value: '5', description: 'ความพึงพอใจมากที่สุด' },
    { label: '4', value: '4', description: 'ความพึงพอใจมาก' },
    { label: '3', value: '3', description: 'ความพึงพอใจปานกลาง' },
    { label: '2', value: '2', description: 'ความพึงพอใจน้อย' },
    { label: '1', value: '1', description: 'ความพึงพอใจน้อยที่สุด' },
  ];
  constructor(
    private _confirmModalService: ConfirmModalService,
    private _modal: ModalServiceService,
    private fb: FormBuilder
  ) {
  }

  ngOnInit() {

    if (this.template) this.questions = this.template.satisfactionSurveyQuestion;
    if (this.questions?.length > 0) this.questions.sort((a: any, b: any) => a.sequence - b.sequence);

    if (this.template?.dataWatch) {
      this.dataWatch = this.template.dataWatch
      this.comments = this.dataWatch.satisfactionSurveyDocument[0]?.comments || '';
    };
    log.debug('data watch', this.dataWatch);

    this.questionForm = new FormGroup({
      questionsAssessment: new FormArray([]),
    })
    this.getQuestion()

  }


  get questionsFromAssessment() {
    return this.questionForm.get('questionsAssessment') as FormArray
  }

  addQuestionAssessment(awnser = null) {
    const questionsAssessment = this.questionForm.get('questionsAssessment') as FormArray;
    questionsAssessment.push(this.fb.control(awnser, Validators.required));
  }

  getQuestion() {
    log.debug('dataWatch dataWatch', this.dataWatch)
    if (this.dataWatch?.satisfactionSurveyDocument[0]?.satisfactionSurveyAnswers) {
      this.dataWatch.satisfactionSurveyDocument[0].satisfactionSurveyAnswers?.forEach((e: any, index: number) => {
        this.addQuestionAssessment(e.text)
        // this.questions.push(e.satisfactionSurveyQuestion.title)
      })
      this.questions = this.dataWatch.satisfactionSurveyDocument[0].satisfactionSurveyAnswers?.map((e: any, index: number) => {
        return e.satisfactionSurveyQuestion
      })
    } else {
      this.questions.forEach((e: any, index: number) => {
        this.addQuestionAssessment()
      })
    }

  }

  async submit() {

    const dataSent:AssessmentAnswer = {
      satisfactionSurveyTemplateId: this.template?.template?.id || null,
      answers: [] as Answer[],
      username: this.username,
      comments: this.comments || '',
      floorId: '' 
    }

    this.questionForm.controls['questionsAssessment'].value.forEach((value: string, index: number) => {

      let answer = {
        text: value || '',
        satisfactionSurveyQuestionId: this.questions ? this.questions[index]?.id : ''
      };
      dataSent.answers.push(answer);
    })

    if (!this.questionForm.controls['questionsAssessment'].valid || !this.username) {
      await this._confirmModalService.ok('กรุณากรอกข้อมูล ให้ครบ');
      return;
    }
    // log.debug('dataSent', dataSent);

    if (await this._confirmModalService.confirm('ยืนยันการประเมิน') !== 'confirm') {
      return;
    }

    this._modal.assessmentConfirm(dataSent);
  }

  async closeModal() {

    if (await this._confirmModalService.confirm(this.status === 'comment' ? 'ออก' : 'ยกเลิกการประเมิน') !== 'confirm') {
      return;
    }

    this._modal.assessmentConfirm(null)
  }

}
