import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-question-card',
  templateUrl: './question-card.component.html',
  styleUrls: ['./question-card.component.scss'],
})
export class QuestionCardComponent implements OnInit {
  @Input() backgroundColor: string = '#d8f3f1';
  constructor() { }

  ngOnInit() { }

}
