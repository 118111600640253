import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/auth/models';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { Logger } from 'src/app/services/logger.service';

const log = new Logger('HeaderProfileComponent')
@Component({
  selector: 'app-header-profile',
  templateUrl: './header-profile.component.html',
  styleUrls: ['./header-profile.component.scss'],
})
export class HeaderProfileComponent implements OnInit {
  userCurrent: User | undefined;
  constructor(
    private _authenticationService: AuthenticationService
  ) {
    this._authenticationService.currentUserSubject.subscribe((e) => {
      if (e) {
        this.userCurrent = this._authenticationService.currentUserValue;
      } else {
        this.userCurrent = undefined;
      }
    })
  }

  ngOnInit() {
    this.userCurrent = this._authenticationService.currentUserValue;
  }

}
