<ion-header>
  <ion-toolbar>
    <ion-title> แบบประเมิน </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding comment-modal">
  <div class="assessment-description">
    <h6>
      {{
        template?.title ||
          "แบบประเมินความพึงพอใจของลูกค้าต่อการให้บริการ ( สำหรับผู้รับบริการ )"
      }}
    </h6>
    <p>โปรดทำเครื่องหมาย ลงในช่องที่ตรงกับระดับความพึงพอใจของท่านมากที่สุด</p>
    <ul>
      <li *ngFor="let item of choices">
        {{ item.value }} หมายถึง {{ item.description }}
      </li>
    </ul>
  </div>

  <div class="assessment-form">
    <h5>แบบฟอร์มประเมิน</h5>
    <form [formGroup]="questionForm">
      <table>
        <thead>
          <tr>
            <th rowspan="2">รายการ</th>
            <th colspan="5" class="title-1">ระดับความคิดเห็นความพึงพอใจ</th>
          </tr>
          <tr>
            <th>5</th>
            <th>4</th>
            <th>3</th>
            <th>2</th>
            <th>1</th>
          </tr>
        </thead>
        <tbody formArrayName="questionsAssessment">
          <!-- <tr *ngFor="let question of questions; let i = index"> -->
          <tr
            *ngFor="
              let questionsAssessment of questionsFromAssessment.controls;
              let i = index
            "
          >
            <td>{{ questions[i].title || "ไม่มีโจทย์" }}</td>

            <td *ngFor="let choice of choices">
              <label>
                <input
                  type="radio"
                  [formControlName]="i.toString()"
                  [value]="choice.value"
                />
              </label>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>

  <div class="form-group mt-3">
    <label for="comment-detail" class="ml-2">ข้อเสนอแนะ</label>
    <textarea
      [readonly]="dataWatch"
      class="form-control"
      id="comment-detail"
      rows="3"
      [(ngModel)]="comments"
      [ngModelOptions]="{ standalone: true }"
    ></textarea>
  </div>

  <div class="form-group mt-3">
    <label for="username"> ลงชื่อ/แผนก <span style="color: red;">*</span> </label>
    <input
      [readonly]="dataWatch"
      class="form-control"
      id="username"
      placeholder="กรอกชื่อ หรือ แผนก"
      rows="3"
      [(ngModel)]="username"
      [ngModelOptions]="{ standalone: true }"
    />
  </div>

  <div class="explanation">
    <p>
      <span>คำชี้แจง</span>
      แบบสอบถามนี้เป็นแบบสอบถามวัดความพึงพอใจในการให้บริการ โดย บริษัท เทคนิคอล
      วอเตอร์ จำกัด
    </p>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar class="ion-padding">
    <ion-button expand="block" [disabled]="dataWatch" (click)="submit()">
      ยืนยันความคิดเห็น</ion-button
    >
    <ion-button expand="block" fill="outline" (click)="closeModal()">
      {{ status === "comment" || dataWatch ? "ออก" : "ยกเลิก" }}</ion-button
    >
  </ion-toolbar>
</ion-footer>
