<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button [hidden]="true">
        <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title> </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding report-modal">



  <div #pageA4 class="a4-page">
    <div class="header1">
      <h3 style="margin-bottom: 1rem">
        {{
          isAirGroup
            ? "ใบรายงานการให้บริการดูแลบำรุงรักษาเครื่องปรับอากาศ"
            : "ใบรายงานการให้บริการดูแลบำรุงรักษาทรัพย์สิน"
        }}
      </h3>
    </div>
    <div class="header2 row">
      <div class="header2-col1 col-8">
        <h4>
          รายละเอียดทรัพย์สิน:
          {{ maintenance?.asset?.name }}
        </h4>
        <h5>ตำแหน่งที่ติดตั้ง: {{ maintenance?.asset?.location?.name }}</h5>
        <h5>สถานที่: {{ maintenance?.asset?.place?.name }}</h5>
        <h5>
          ชนิดเครื่อง: {{ pipeAssetTypeForName(maintenance?.asset?.group) }}
        </h5>
        <h5>
          ยี่ห้อ: {{ maintenance?.asset?.assetInfos[0]?.manufacturer || "-" }}
        </h5>
        <h5>
          หมายเลขเครื่อง: {{ maintenance?.asset?.assetInfos[0]?.model || "-" }}
        </h5>
        <h5 *ngIf="isAirGroup">
          ขนาดทำความเย็น: {{ maintenance?.asset?.assetInfos[0]?.btu || "-" }}
        </h5>
      </div>
      <div class="header2-col2 col-4">
        <div class="block-sevice">
          <h5>ลักษณะการบริการ</h5>
          <div class="b-body">
            <ul>
              <li>
                <ion-icon name="checkbox-outline"></ion-icon> ตามสัญญาบริการ
              </li>
              <li><ion-icon name="square-outline"></ion-icon> ทดสอบระบบ</li>
              <li><ion-icon name="square-outline"></ion-icon> บริการงานซ่อม</li>
              <li><ion-icon name="square-outline"></ion-icon> อื่นๆ</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="header3">
      <h5>
        ประเภทการบำรุงรักษา :
        {{ pipeMaintenanceTypeForName(maintenance?.maintenanceType) }}
      </h5>
    </div>

    <div class="table-form-wash--air" *ngIf="isAirGroup">
      <table class="table" style="border: 1px solid">
        <thead>
          <tr>
            <th>รายละเอียดการตรวจเช็คและดำเนินการ</th>
            <th>ก่อน</th>
            <th>ตรวจเช็ค</th>
            <th>หลัง</th>
          </tr>
        </thead>
        <tbody class="tbody-1">
          <tr
            *ngFor="
              let pre of preMaintenance?.maintenanceDocumentAnswers;
              let i = index
            "
          >
            <td>{{ pre?.maintenanceDocumentQuestion?.title }}</td>
            <td>{{ pre?.maintenanceDocumentChoice?.title || pre?.text }}</td>
            <td></td>
            <td>
              {{
                postMaintenance.maintenanceDocumentAnswers[i]
                  .maintenanceDocumentChoice?.title ||
                  postMaintenance.maintenanceDocumentAnswers[i].text
              }}
            </td>
          </tr>
          <tr *ngFor="let exe of exeMaintenance?.maintenanceDocumentAnswers">
            <td>{{ exe?.maintenanceDocumentQuestion?.title }}</td>
            <td></td>
            <td>
              {{ exe?.text }}

              <ion-icon
                *ngIf="exe?.maintenanceDocumentChoice?.title === 'ตรวจสอบแล้ว'"
                name="checkmark-outline"
              ></ion-icon>

              <ion-icon
                *ngIf="
                  exe?.maintenanceDocumentChoice?.title === 'ยังไม่ได้ตรวจสอบ'
                "
                name="close-outline"
              ></ion-icon>

              {{
                exe?.maintenanceDocumentChoice?.title !== "ตรวจสอบแล้ว" &&
                exe?.maintenanceDocumentChoice?.title !== "ยังไม่ได้ตรวจสอบ"
                  ? exe?.maintenanceDocumentChoice?.title
                  : null
              }}
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="!isAirGroup" class="container">
      <div *ngFor="let exe of exeMaintenance?.maintenanceDocumentAnswers">
        <div class="title-exe mt-1">
          {{ exe?.maintenanceDocumentQuestion?.title }} :
        </div>
        <p class="text-exe">{{ exe?.text }}</p>
      </div>
    </div>

    <div>
      <h5>
        การทำงานแล้วเสร็จ :
        <ion-icon name="square-outline"></ion-icon> เรียบร้อย
        <ion-icon name="square-outline"></ion-icon> ไม่เรียบร้อย
        <ion-icon name="square-outline"></ion-icon> อื่นๆ
      </h5>
    </div>

    <div class="table-responsive table-2">
      <table class="table table-sm">
        <thead>
          <tr>
            <td class="font-weight-bolder">ความเห็นของวิศวกรรม:</td>
            <td class="font-weight-bolder">ความเห็นของหน่วยงาน:</td>
            <td class="font-weight-bolder">ความเห็นของทีมช่าง:</td>
            <td class="font-weight-bolder">เวลาดำเนินการ:</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="signature-engineer"></td>
            <td class="signature-agency"></td>
            <td class="comment">
              {{ maintenance?.comments[0]?.comment || "-" }}
              <hr />
              <div class="title-detail">
                <div class="font-weight-bolder mr-1">หมายเหตุ:</div>
                <div class="detail">
                  {{ maintenance?.comments[0]?.text || "-" }}
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex">
                <div class="font-weight-bolder mr-1">วันที่:</div>
                <div class="date-createdAt">
                  {{ dateTh(maintenance?.createdAt) }} น.
                </div>
              </div>
            </td>
          </tr>
          <tr class="tr-signature">
            <td class="font-weight-bolder con-signature">
              <div class="signed-name">ลงชื่อวิศวกรรม:</div>
              <div class="img-signed">
                <img
                  *ngIf="
                    maintenance?.MaintenanceSignature?.length &&
                    maintenance?.MaintenanceSignature[0]?.signatureType ===
                      'ENGINEER'
                  "
                  [src]="maintenance?.MaintenanceSignature[0].signatureImage"
                  alt="signature"
                />
                <div
                  style="height: 72px"
                  *ngIf="
                    !maintenance?.MaintenanceSignature?.length ||
                    maintenance?.MaintenanceSignature[0]?.signatureType !==
                      'ENGINEER'
                  "
                ></div>
              </div>
            </td>
            <td class="font-weight-bolder con-signature">
              <div class="signed-name">ลงชื่อหน่วยงาน:</div>
              <div class="img-signed">
                <img
                  *ngIf="
                    maintenance?.MaintenanceSignature?.length &&
                    maintenance?.MaintenanceSignature[0]?.signatureType ===
                      'AGENCY'
                  "
                  [src]="maintenance?.MaintenanceSignature[0].signatureImage"
                  alt="signature"
                />
                <div
                  style="height: 72px"
                  *ngIf="
                    !maintenance?.MaintenanceSignature?.length ||
                    maintenance?.MaintenanceSignature[0]?.signatureType !==
                      'AGENCY'
                  "
                ></div>
              </div>
            </td>
            <td class="font-weight-bolder con-signature">
              <div class="signed-name">ลงชื่อทีมช่าง:</div>
              <div class="img-signed">
                <div style="height: 72px"></div>
              </div>
            </td>
            <td class="font-weight-bolder con-signature">
              <div class="completion-time">เวลาแล้วเสร็จ:</div>
              <div class="time">
                <div></div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="font-weight-bolder">
              วันที่:
              <span>
                {{
                  maintenance?.MaintenanceSignature?.length &&
                  maintenance?.MaintenanceSignature[0]?.signatureType ===
                    "ENGINEER"
                    ? dateTh(maintenance?.MaintenanceSignature[0]?.createdAt)
                    : null
                }}
              </span>
            </td>
            <td class="font-weight-bolder">
              วันที่:
              <span>
                {{
                  maintenance?.MaintenanceSignature?.length &&
                  maintenance?.MaintenanceSignature[0]?.signatureType ===
                    "AGENCY"
                    ? dateTh(maintenance?.MaintenanceSignature[0]?.createdAt) 
                    : null
                }}
              </span>
            </td>
            <td class="font-weight-bolder">วันที่:</td>
            <td>
              <div class="d-flex">
                <div class="font-weight-bolder mr-1">วันที่:</div>
                <div class="date-updatedAt">
                  {{ dateTh(maintenance?.updatedAt) }} น.
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ion-content>
