import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { InformationComponent } from './pages/informations/information/information.component';
import { SignInComponent } from './auth/pages/sign-in/sign-in.component';
import { LoginWithAssetTokenComponent } from './auth/pages/login-with-asset-token/login-with-asset-token.component';
import { TabsPage } from './tabs/tabs.page';
import { SignUpComponent } from './auth/pages/sign-up/sign-up.component';
import { AuthGuard } from './auth/helper/auth.guards';
const routes: Routes = [
  {
    path: 'sign-in',
    component: SignInComponent,
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'sign-up',
    component: SignUpComponent,
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'login-with-asset-token/:assetToken',
    component: LoginWithAssetTokenComponent,
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'assessment',
    children: [
      {
        path: "",
        loadChildren: () => import('./pages/assessment/assessment.module').then(m => m.AssessmentModule)
      },
    ],

  },
  // {
  //   path: 'floors',
  //   children: [
  //     {
  //       path: "",
  //       loadChildren: () => import('./pages/floors/floor.module').then(m => m.FloorModule)
  //     },
  //   ],

  // },
  {
    path: 'forms',
    loadChildren: () => import('./pages/forms/forms.module').then(m => m.FormsPageModule)
    , canActivate: [AuthGuard]
  },
  {
    path: "assets/:id",
    redirectTo: "tabs/assets/:id"
  },
  {
    path: "group-assets/:id",
    redirectTo: "tabs/assessment/group-assets/:id"
  },
  {
    path: "tabs/floors/:id",
    redirectTo: "tabs/floors/floor/:id"
  },
  {
    path: "floors/:id",
    redirectTo: "tabs/floors/floor/:id"
  },
  {
    path: "tabs/group-assets/:id",
    redirectTo: "tabs/assessment/group-assets/:id"
  },
  {
    path: "tabs/assets/:id",
    // component: InformationComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/informations/informations.module").then(
            (m) => m.InformationPagePageModule
          ),
      },
    ],
  },
  {
    path: '',
    redirectTo: 'tabs',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'tabs/error',
    pathMatch: 'full'
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
