<ion-grid class="ion-padding">
  <ion-row class="row-profile">
    <ion-col size="9">
      <h4>{{ userCurrent?.role }}</h4>
      <p class="mt-1">{{ userCurrent?.firstname }} {{ userCurrent?.lastname }}</p>
    </ion-col>

    <ion-col size="3">
      <ion-avatar>
        <img
          alt="{{userCurrent?.firstname}}"
          [src]="userCurrent?.picture ||'https://ionicframework.com/docs/img/demos/avatar.svg'"
        />
      </ion-avatar>
    </ion-col>
  </ion-row>
</ion-grid>
