import { Component, OnInit } from '@angular/core';
import { Logger } from 'src/app/services/logger.service';
import { AuthenticationService } from '../../services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { first } from 'rxjs';
import { ConfirmModalService } from 'src/app/components/modals/confirm-modal.service';

const log = new Logger('LoginWithAssetTokenComponent');
@Component({
  selector: 'app-login-with-asset-token',
  templateUrl: './login-with-asset-token.component.html',
  styleUrls: ['./login-with-asset-token.component.scss'],
})
export class LoginWithAssetTokenComponent implements OnInit {

  assetToken: any;

  constructor(
    private _authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private _apiService: ApiService,
    private _router: Router,
    private _confirmModalService: ConfirmModalService,
  ) { }

  ngOnInit() {
    this.assetToken = this.activatedRoute.snapshot.paramMap.get('assetToken');
    this.loginWithToken()
  }


   loginWithToken() {
    if (this.assetToken) {
      this._authenticationService.loginWithWToken(this.assetToken).pipe(first())
        .subscribe(
          (data) => {
            this._router.navigate(['tabs/tab-scan']);
          },
          (error) => {
            log.error('error sign in', error);
            this._confirmModalService.ok('ไม่สามารถเข้าสู่ระบบได้', error.error.message);
            this._router.navigate(['/']);
          }
        );
    } else {
      this._router.navigate(['/']);
    }
  }
}
