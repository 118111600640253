import { Inject, Injectable } from '@angular/core';
// import { BROWSER_STORAGE } from '../helper/storage';
// import { UserService } from './user.service';
// import jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Logger } from 'src/app/services/logger.service';
import { User } from '../models';
import { Token } from '../models';
const log = new Logger('AuthenticationService')
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public currentUser: Observable<any>;
  public currentToken: Observable<any>;

  public currentUserSubject: BehaviorSubject<any>;
  public twmssTokenSubject: BehaviorSubject<any>;

  /**
  *
  * @param {HttpClient} _http
  */


  constructor(private _http: HttpClient) {
    // let cu: string | null = localStorage.getItem('currentUser');
    // const tk: string | null = localStorage.getItem('TWMSS_TOKEN');
    let cu = null;
    let tk = null;
    try {
      cu = localStorage.getItem('currentUser');
    } catch (e) {
      console.error('Error accessing localStorage:', e);
    }
    try {
      tk = localStorage.getItem('TWMSS_TOKEN');
    } catch (e) {
      console.error('Error accessing localStorage:', e);
    }
    
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('currentUser')!)
    );
    this.twmssTokenSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('TWMSS_TOKEN')!)
    );

    if (cu !== null) {
      this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(cu));
    } else {
      this.currentUserSubject = new BehaviorSubject<any>(cu);
    }

    if (tk !== null) {
      this.twmssTokenSubject = new BehaviorSubject<any>(JSON.parse(tk));
    } else {
      this.twmssTokenSubject = new BehaviorSubject<any>(tk);
    }


    this.currentUser = this.currentUserSubject.asObservable();
    this.currentToken = this.twmssTokenSubject.asObservable();
  }


  // getAccessToken() {
  //   return null;
  // }



  public get currentUserValue(): User {
    return this.currentUserSubject.getValue();
  }
  public get currentTokenValue(): Token {
    return this.twmssTokenSubject.getValue();
  }

  login(username: string, password: string) {

    return this._http
      .post<any>(`${environment.apiUrl}/auth/signin`, { username, password })
      .pipe(
        map(data => {
          this.setLogin(data);
          return data;
        })
      );
  }




  register({
    email = null,
    password = null,
    firstname = null,
    lastname = null }) {

    return this._http
      .post<any>(`${environment.apiUrl}/auth/signup`, { email, password, firstname, lastname })
      .pipe(
        map(data => {
          this.setLogin(data);
          return data;
        })
      );
  }


  loginWithWToken(assetToken: string) {
    const url = `${environment.apiUrl}/auth/users/token/${assetToken}`;
    return this._http.get<any>(url).pipe(
      map(data => {
        this.setLogin(data);
        return data;
      })
    )
  }


  setLogin(data: User) {

    if (data && data?.tokens) {
      localStorage.setItem('currentUser', JSON.stringify(data.user));
      localStorage.setItem('TWMSS_TOKEN', JSON.stringify(data.tokens));
      setTimeout(() => {

      }, 2500);
      this.currentUserSubject.next(data.user);
      this.twmssTokenSubject.next(data.tokens);
    }
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("TWMSS_TOKEN");
    // notify
    this.currentUserSubject.next(null);
    this.twmssTokenSubject.next(null);
  }


  updateProfile({ firstname = '', lastname = '', mobilePhone = '', email = '' }, picture: File): Promise<any> {

    const formData = new FormData();

    formData.append('firstname', firstname);
    formData.append('lastname', lastname);
    formData.append('mobilePhone', mobilePhone);
    formData.append('email', email);

    if (picture) {
      formData.append('picture', picture);
    }

    return this._http
      .patch<any>(`${environment.apiUrl}/mobile-platform/me`, formData)
      .pipe(
        map(data => {
          if (data) {
            localStorage.setItem('currentUser', JSON.stringify(data));
            setTimeout(() => {

            }, 2500);
            this.currentUserSubject.next(data);
          }
          return data;
        })
      ).toPromise();
  }


  uploadProfilePicture(picture: File): Promise<any> {

    const formData = new FormData();
    if (picture) {
      formData.append('picture', picture);
    }
    return this._http
      .patch<any>(`${environment.apiUrl}/mobile-platform/me`, formData)
      .pipe(
        map(data => {
          if (data) {
            localStorage.setItem('currentUser', JSON.stringify(data));
            setTimeout(() => {

            }, 2500);
            this.currentUserSubject.next(data);
          }
          return data;
        })
      ).toPromise();
  }




}
