
export const assetType = [
    {
        name:'แอร์คอนดิชั่น',
        value:'AIRCONDITION'
    },
    {
        name:'แอร์ FCU',
        value:'AIRCONDITION_FCU'
    },
    {
        name:'แอร์ AHU',
        value:'AIRCONDITION_AHU'
    },
    {
        name:'พัดลมระบายอากาศ',
        value:'VENTILATIONFAN'
    },
    {
        name:'ระบบสัญญาณประตูหนีไฟ',
        value:'FIRE_ALARM_SYSTEM'
    },
    {
        name:'ลิฟท์ส่งยา',
        value:'DUMB_WAITERS_LIFT'
    },
    {
        name:'สถานีแก๊ส',
        value:'GAS_DETECTOR'
    },
    {
        name:'เครื่อง Fire pump , jocker',
        value:'FIRE_PUMP_JOCKER'
    },
    {
        name:'เครื่อง UPS',
        value:'UPS'
    },
    {
        name:'เครื่อง Generator',
        value:'GENERATOR'
    },
] 


export class MapTypeAsset {
    static getName(assetCode:string) {
        const asset = assetType.find(asset => asset.value === assetCode);
        return asset ? asset.name : null;
    }
}