<ion-button class="ion-padding-horizontal" id="open-modal" expand="block"
  >ลงชื่อเข้าใช้</ion-button
>

<ion-modal trigger="open-modal" (willDismiss)="onWillDismiss($event)">
  <ng-template>
    <!-- <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button (click)="cancel()">Cancel</ion-button>
        </ion-buttons>
        <ion-title>ลงชื่อเข้าใช้</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="confirm()" [strong]="true">Confirm</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header> -->

    <ion-header [translucent]="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button (click)="cancel()">
            <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>ลงชื่อเข้าใช้</ion-title>
        <ion-buttons slot="end">
          <ion-button>
            <ion-icon [hidden]="true" slot="icon-only" name="scan"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <ion-grid>
        <ion-row class="ion-padding">
          <ion-col  class="ion-text-center">
            <img src="/assets/logo/logo_twmss.png" alt="logo" />
          </ion-col>
        </ion-row>
      </ion-grid>

      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="username">Username</label>
          <input
            formControlName="username"
            type="text"
            class="form-control"
            id="username"
            aria-describedby="emailHelp"
            placeholder="Enter username"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">Password</label>
          <div class="input-group input-group-merge form-password-toggle">
            <input
              formControlName="password"
              [type]="passwordTextType ? 'text' : 'password'"
              class="form-control"
              id="exampleInputPassword1"
              placeholder="Password"
            />
            <div class="input-group-append">
              <span
                class="input-group-text cursor-pointer"
                (click)="togglePasswordTextType()"
              >
                <ion-icon
                  [name]="passwordTextType ? 'eye-outline' : 'eye-off-outline'"
                ></ion-icon>
              </span>
            </div>
          </div>
        </div>
        <ion-button class="mt-1" type="submit" expand="block">
          ลงชื่อเข้าใช้
        </ion-button>
        <ion-button class="mt-2" (click)="cancel()" fill="outline" expand="block">
          ยกเลิก
        </ion-button>
      </form>
    </ion-content>
  </ng-template>
</ion-modal>
