import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { ConfirmModalService } from 'src/app/components/modals/confirm-modal.service';

import { Logger } from 'src/app/services/logger.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs';
const log = new Logger('SignUpComponent')

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  public registerForm!: FormGroup;
  public loading = false;
  passwordTextType = false;
  constructor(
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _confirmModalService: ConfirmModalService,
  ) { }

  ngOnInit() {
    this.registerForm = this._formBuilder.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
      confirmPassword: ["", Validators.required],
    });

  }

  async passwordMatchValidator(control: AbstractControl): Promise<{ [key: string]: boolean; } | null> {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');

    if (password?.value !== confirmPassword?.value) {
      return { 'passwordMismatch': true };
    }

    return null;
  }



  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType
  }

  onSubmit() {

    if (this.registerForm.valid && this.registerForm.value?.password === this.registerForm.value?.confirmPassword) {
      const { email, firstName, lastName, password } = this.registerForm.value

      this._authenticationService.register({ email, firstname: firstName, lastname: lastName, password })
        .pipe(first())
        .subscribe(
          (data) => {
            this.registerForm.reset();
            this._router.navigate(['tabs']);
          },
          (error) => {
            log.error('error register', error);
            this._confirmModalService.ok('ไม่สามารถลงทะเบียนได้', error.error.message)
            this.loading = false;
          }
        );
    }
  }

}
