import { Injectable } from '@angular/core';
import { AlertController, ToastController } from "@ionic/angular";
import { Subject } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';

@Injectable({
  providedIn: 'root'
})
export class ConfirmModalService {


  private textShow: any;

  constructor(
    public toastController: ToastController,
    public alertController: AlertController,
    public _loadingService: LoadingService,
  ) {
  }

  async successAlert(header = 'รายการสำเร็จ', message?: string, duration = 2000 ) {
    if (this.textShow) {
      this.textShow.dismiss();
      this.textShow = null;
    }
    this.textShow = await this.toastController.create({
      header,
      message,
      duration,
      icon: 'checkmark-circle',
      position: 'top',
      color: 'success',
      translucent: true,
      animated: true,
      mode: 'md'
    });
    await this.textShow.present();
  }

  async errorAlert(header = 'รายการไม่สำเร็จ', message?: string, duration = 1000) {

    if (this.textShow) {
      this.textShow.dismiss();
      this.textShow = null;
    }
    this.textShow = await this.toastController.create({
      header,
      message,
      duration,
      icon: 'information-circle',
      position: 'top',
      color: 'danger',
      translucent: true,
      animated: true,
      mode: 'md'
    });
    await this.textShow.present();
  }

  async showAlert({ header = 'ข้อความ', message = '', duration = 1000 }) {

    if (this.textShow) {
      this.textShow.dismiss();
      this.textShow = null;
    }
    this.textShow = await this.toastController.create({
      header,
      message,
      duration,
      icon: 'checkmark-circle',
      position: 'top',
      color: 'primary',
      translucent: true,
      animated: true,
      mode: 'md'
    });
    await this.textShow.present();
  }

  async ok(header: string, message = ''): Promise<any> {
    this._loadingService.notShowAlert$.next(null);
    const alert = await this.alertController.create({
      header,
      message,
      buttons: [
        {
          text: 'ตกลง',
          role: 'confirm'
        }
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }

  async confirm(header: any, message?: string) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: [
        {
          text: 'ยกเลิก',
          role: 'cancel',
          // cssClass: 'secondary',
        }, {
          text: 'ตกลง',
          role: 'confirm'
        }
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }


}
