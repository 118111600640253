import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Logger } from './logger.service';
import { Subject } from 'rxjs';
import { CreateSignature, PatchMaintenanceRepair } from '../interfaces';
import { AssessmentAnswer } from '../interfaces/assessment';


const log = new Logger('ApiMaintenanceService');

@Injectable({
  providedIn: 'root'
})
export class ApiMaintenanceService {
  public editMaintenance$ = new Subject<any>();
  public editMaintenanceRepair$ = new Subject<any>();

  public fetchData$ = new Subject<any>()
  public fetchAsset$ = new Subject<any>();
  constructor(
    private httpClient: HttpClient,
  ) { }

  getAssets({ statusType = 'clean', group = '', placeId = '', identifierNumber = '', name = '', status = '', limit = 10, page = 1 }): Promise<any> {

    const url = `${environment.apiUrl}/mobile-platform/assets`;
    let params = new HttpParams()
      .set('statusType', statusType)
      .set('placeId', placeId)
      .set('group', group)
      .set('identifierNumber', identifierNumber)
      .set('name', name)
      .set('status', status)
      .set('limit', limit.toString())
      .set('page', page.toString())

    return this.httpClient
      .get(url, { params })
      .toPromise()
      .then((response) => response);
  }


  getAssetsObserve({ statusType = 'clean', group = '', placeId = '', identifierNumber = '', name = '', status = '', limit = 10, page = 1 }) {

    const url = `${environment.apiUrl}/mobile-platform/assets`;
    let params = new HttpParams()
      .set('statusType', statusType)
      .set('placeId', placeId)
      .set('group', group)
      .set('identifierNumber', identifierNumber)
      .set('name', name)
      .set('status', status)
      .set('limit', limit.toString())
      .set('page', page.toString())

    return this.httpClient.get(url, { params })
  }



  getLastPending(assetId: string): Promise<any> {
    const url = `${environment.apiUrl}/mobile-platform/assets/get-latest-pending-maintenance/${assetId}`;
    let params = new HttpParams();
    return this.httpClient
      .get(url, { params })
      .toPromise()
      .then((response) => response);
  }
  getLastPendingRepair(assetId: string): Promise<any> {
    const url = `${environment.apiUrl}/mobile-platform/assets/get-latest-pending-maintenance-repair/${assetId}`;
    let params = new HttpParams();
    return this.httpClient
      .get(url, { params })
      .toPromise()
      .then((response) => response);
  }

  createMaintenance(assetId: string): Promise<any> {
    const url = `${environment.apiUrl}/mobile-platform/maintenances`;
    let params = new HttpParams();
    return this.httpClient
      .post(url, { assetId: assetId })
      .toPromise()
      .then((response) => response);
  }
  getMaintenanceById(maintenanceId: string): Promise<any> {
    const url = `${environment.apiUrl}/mobile-platform/maintenances/` + maintenanceId;
    return this.httpClient
      .get(url)
      .toPromise()
      .then((response) => response);
  }
  createMaintenanceRepairs(assetId: string): Promise<any> {
    const url = `${environment.apiUrl}/mobile-platform/maintenance-repairs`;
    let params = new HttpParams();
    return this.httpClient
      .post(url, { assetId: assetId })
      .toPromise()
      .then((response) => response);
  }
  createMaintenanceDocument(dataTemp: object): Promise<any> {
    const url = `${environment.apiUrl}/mobile-platform/maintenance-documents`;
    let params = new HttpParams();
    return this.httpClient
      .post(url, dataTemp)
      .toPromise()
      .then((response) => response);
  }

  updateMaintenanceDocument(dataTemp: object, documentId: string): Promise<any> {
    const url = `${environment.apiUrl}/mobile-platform/maintenance-documents/${documentId}`;
    let params = new HttpParams();
    return this.httpClient
      .patch(url, dataTemp)
      .toPromise()
      .then((response) => response);
  }

  createMaintenanceComment(dataTemp: object) {
    const url = `${environment.apiUrl}/mobile-platform/maintenances/create-or-update-comment`;
    let params = new HttpParams();
    return this.httpClient
      .post(url, dataTemp)
      .toPromise()
      .then((response) => response);
  }
  createOrUpdateMaintenanceRepairComment(dataTemp: object) {
    const url = `${environment.apiUrl}/mobile-platform/maintenance-repairs/create-or-update-comment`;
    let params = new HttpParams();
    return this.httpClient
      .post(url, dataTemp)
      .toPromise()
      .then((response) => response);
  }


  getMaintenanceRepair({
    placeId = '',
    userId = '',
    assetId = '',
    identifierNumber = '',
    maintenanceStatus = '',
    orderBy = 'updatedAt:desc',
    page = 1,
    limit = 10,
  }){

    const url = `${environment.apiUrl}/mobile-platform/maintenance-repairs`;

    let params = new HttpParams()
      .set('placeId', placeId)
      .set('userId', userId)
      .set('assetId', assetId)
      .set('orderBy', orderBy)
      .set('maintenanceStatus', maintenanceStatus)
      .set('page', page.toString())
      .set('limit', limit.toString())
    return this.httpClient
      .get(url, { params })
      // .toPromise()
      // .then((response) => response);
  }


  async getMaintenanceRepairById(id: string) {
    const url = `${environment.apiUrl}/mobile-platform/maintenance-repairs/${id}`;
    return await this.httpClient
      .get(url)
      .toPromise();
  }


  uploadFileMaintenanceImage(maintenanceId: string, dataTemp: object) {
    const url = `${environment.apiUrl}/mobile-platform/maintenances/${maintenanceId}`;
    return this.httpClient
      .patch(url, dataTemp)
      .toPromise()
      .then((response) => response);
  }
  updateMaintenanceRepair(repairId: string, dataTemp: PatchMaintenanceRepair | any) {
    const url = `${environment.apiUrl}/mobile-platform/maintenance-repairs/${repairId}`;
    return this.httpClient
      .patch(url, dataTemp)
      .toPromise()
      .then((response) => response);
  }

  deleteMaintenanceImage(dataTemp: object) {
    const url = `${environment.apiUrl}/mobile-platform/maintenances/images`;
    return this.httpClient
      .delete(url, { body: dataTemp })
      .toPromise()
      .then((response) => response);
  }
  deleteMaintenanceRepairImage(dataTemp: object) {
    const url = `${environment.apiUrl}/mobile-platform/maintenance-repairs/images`;
    let params = new HttpParams();
    return this.httpClient
      .delete(url, { body: dataTemp })
      .toPromise()
      .then((response) => response);
  }


  getMaintenanceQuestions({ type = '', group = '' }): Promise<any> {
    const url = `${environment.apiUrl}/mobile-platform/maintenance-document-templates`;

    let params = new HttpParams()
      .set('type', type)
      .set('group', group)
    return this.httpClient
      .get(url, { params })
      .toPromise()
      .then((response) => response);
  }
  getMaintenanceHistory({
    placeId = '',
    userId = '',
    assetId = '',
    identifierNumber = '',
    maintenanceType = '',
    maintenanceStatus = '',
    orderBy = 'updatedAt:desc',
    page = 1,
    limit = 10,
  }) {

    const url = `${environment.apiUrl}/mobile-platform/maintenances`;

    let params = new HttpParams()
      .set('identifierNumber', identifierNumber.toString())
      .set('placeId', placeId)
      .set('userId', userId)
      .set('assetId', assetId)
      .set('orderBy', orderBy)
      .set('maintenanceType', maintenanceType)
      .set('maintenanceStatus', maintenanceStatus)
      .set('page', page.toString())
      .set('limit', limit.toString())
    return this.httpClient
      .get(url, { params })
      // .toPromise()
      // .then((response) => response);
  }



  getAssessment({ groupId = '', status = 'nocomment', limit = 10 }): Promise<any> {
    // status = comment | nocomment
    console.log('status', status)
    const url = `${environment.apiUrl}/mobile-platform/group-assets/maintenances/${groupId}`;
    let params = new HttpParams()
      .set('status', status)
      .set('limit', limit.toString())
      .set('page', '1')
    // .set('orderBy', 'date:desc')
    return this.httpClient
      .get(url, { params })
      .toPromise()
      .then((response) => response);
  }


  assessmentOne(dataTemp: AssessmentAnswer): Promise<any> {
    const url = `${environment.apiUrl}/mobile-platform/satisfaction-survey-documents`;
    return this.httpClient
      .post(url, dataTemp)
      .toPromise()
      .then((response) => response);
  }

  assessmentAll(dataTemp: object): Promise<any> {
    const url = `${environment.apiUrl}/mobile-platform/satisfaction-survey-documents/multi-create`;
    return this.httpClient
      .post(url, dataTemp)
      .toPromise()
      .then((response) => response);
  }

  getSatisfactionTemplates(): Promise<any> {
    const url = `${environment.apiUrl}/mobile-platform/satisfaction-survey-templates`;
    let params = new HttpParams()
      .set('limit', '100')
    return this.httpClient
      .get(url, { params })
      .toPromise()
      .then((response) => response);
  }


  createSignature(dataTemp: any): Promise<any> {

    const url = `${environment.apiUrl}/mobile-platform/maintenance-signatures`;
    return this.httpClient
      .post(url, dataTemp)
      .toPromise()
      .then((response) => response);
  }






}
