import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { InfoCardComponent } from './cards/info-card/info-card.component';
import { ContactCardComponent } from './cards/contact-card/contact-card.component';
import { MenuCardComponent } from './cards/menu-card/menu-card.component';
import { NoItemComponent } from './cards/no-item/no-item.component';
import { QuestionCardComponent } from './cards/question-card/question-card.component';
import { CommentModalComponent } from './modals/comment-modal/comment-modal.component';
import { AssessmentModalComponent } from './modals/assessment-modal/assessment-modal.component';
import { LoginModalComponent } from './modals/login-modal/login-modal.component';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignaturePadComponent } from './shared/signature-pad/signature-pad.component';
import { MaintenanceCardComponent } from './cards/maintenance-card/maintenance-card.component';
import { SignatureModalComponent } from './modals/signature-modal/signature-modal.component';
import { ReportMaintenanceModalComponent } from './modals/report-maintenance-modal/report-maintenance-modal.component';
import { ReportMaintenanceRepairModalComponent } from './modals/report-maintenance-repair-modal/report-maintenance-repair-modal.component';

@NgModule({
    declarations: [
        InfoCardComponent,
        ContactCardComponent,
        MenuCardComponent,
        NoItemComponent,
        QuestionCardComponent,
        CommentModalComponent,
        AssessmentModalComponent,
        LoginModalComponent,
        SignaturePadComponent,
        MaintenanceCardComponent,
        SignatureModalComponent,
        ReportMaintenanceModalComponent,
        ReportMaintenanceRepairModalComponent
    ],
    exports: [
        InfoCardComponent,
        ContactCardComponent,
        MenuCardComponent,
        NoItemComponent,
        QuestionCardComponent,
        CommentModalComponent,
        AssessmentModalComponent,
        LoginModalComponent,
        SignaturePadComponent,
        MaintenanceCardComponent,
        SignatureModalComponent,
        ReportMaintenanceModalComponent,
        ReportMaintenanceRepairModalComponent
    ],
    imports: [
        CommonModule,
        IonicModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
    ]
})
export class ComponentsModule { }