// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://trydev-api.technicalwater.app',
  baseDomainUrl: 'https://trydev-mobile.technicalwater.app/',

  // # secret
  JWT_ACCESS_SECRET: 'twmssServerSecret',
  JWT_REFRESH_SECRET: 'twmssServerRefreshSecret',
  JWT_LOGIN_SECRET: 'twmssServerLoginSecret',

  sentry: {
    enable: true,
    environment: 'develop',
    dsn: 'https://83bb2d89df724265b194260fd1de0598@sentry.rawinlab.com/13',
    tracesSampleRate: 0.1,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
