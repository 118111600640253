<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button [hidden]="true">
        <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title> เซ็นชื่อรับรอง </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-row *ngIf="type === 'CLEAN'">
  <ion-col class="col-6">
    <ion-select
      label="ตำแหน่ง"
      label-placement="floating"
      fill="outline"
      mode="md"
      [(ngModel)]="selectSignatureType"
      (ionChange)="choiceSignatureType($event)"
    >
      <ion-select-option value="ENGINEER"> วิศวะกรรม </ion-select-option>
      <ion-select-option value="AGENCY"> หน่วยงาน </ion-select-option>
    </ion-select>
  </ion-col>
</ion-row>

<div class="con-canvas">
  <canvas
    #canvasSignature
    (touchstart)="startDrawing($event)"
    (touchmove)="moved($event)"
    width="700"
    height="200"
  ></canvas>
</div>

<div class="con-btn">
  <!-- <ion-button fill="outline" color="danger" (click)="close()">ปิด</ion-button> -->
  <ion-button fill="outline" (click)="clearPad()">ล้าง</ion-button>
  <ion-button (click)="savePad()">ยืนยัน</ion-button>
</div>
