<app-header [hideIconRight]="true" title="ลงทะเบียน"> </app-header>

<ion-content class="ion-padding">
  <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="firstName"> ชื่อจริง </label>
      <input
        formControlName="firstName"
        type="text"
        class="form-control"
        id="firstName"
        placeholder="กรุณากรอกชื่อจริง"
      />
      <small
        id="emailHelp"
        class="form-text text-muted"
        *ngIf="
          registerForm.get('firstName')?.errors &&
          registerForm.get('firstName')?.touched
        "
      >
        กรุณากรอกชื่อจริง
      </small>
    </div>
    <div class="form-group">
      <label for="lastName">นามสกุล</label>
      <input
        formControlName="lastName"
        type="text"
        class="form-control"
        id="lastName"
        placeholder="กรุณากรอกนามสกุล"
      />
      <small
        id="emailHelp"
        class="form-text text-muted"
        *ngIf="
          registerForm.get('lastName')?.errors &&
          registerForm.get('lastName')?.touched
        "
      >
        กรุณากรอกนามสกุล
      </small>
    </div>
    <div class="form-group">
      <label for="exampleInputEmail1">Email address</label>
      <input
        formControlName="email"
        type="email"
        class="form-control"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        placeholder="Enter email"
      />
      <small
        id="emailHelp"
        class="form-text text-muted"
        *ngIf="
          registerForm.get('email')?.errors &&
          registerForm.get('email')?.touched
        "
      >
        กรุณากรอกอีเมลให้ถูกต้อง</small
      >
    </div>
    <div class="form-group">
      <label for="exampleInputPassword1">Password</label>
      <div class="input-group input-group-merge form-password-toggle">
        <input
          formControlName="password"
          [type]="passwordTextType ? 'text' : 'password'"
          class="form-control"
          [minlength]="6"
          id="password"
          placeholder="Password"
        />
        <div class="input-group-append">
          <span
            class="input-group-text cursor-pointer"
            (click)="togglePasswordTextType()"
          >
            <ion-icon
              [name]="passwordTextType ? 'eye-outline' : 'eye-off-outline'"
            ></ion-icon>
          </span>
        </div>
      </div>
      <small
        class="form-text text-muted"
        style="color: firebrick"
        *ngIf="
          registerForm.get('password')?.errors &&
          registerForm.get('password')?.touched
        "
      >
        รหัสผ่านอย่างน้อย 6 ตัว
      </small>
    </div>
    <div class="form-group">
      <label for="confirmPassword">Confirm Password</label>
      <div class="input-group input-group-merge form-password-toggle">
        <input
          formControlName="confirmPassword"
          [type]="passwordTextType ? 'text' : 'password'"
          class="form-control"
          [minlength]="6"
          id="confirmPassword"
          placeholder="กรอก password ให้ตรงกัน"
        />
        <div class="input-group-append">
          <span
            class="input-group-text cursor-pointer"
            (click)="togglePasswordTextType()"
          >
            <ion-icon
              [name]="passwordTextType ? 'eye-outline' : 'eye-off-outline'"
            ></ion-icon>
          </span>
        </div>
      </div>
      <small
        class="form-text text-muted"
        style="color: firebrick"
        *ngIf="
          registerForm.get('password')?.value !==
            registerForm.get('confirmPassword')?.value &&
          registerForm.get('confirmPassword')?.touched
        "
      >
        รหัสผ่านไม่ตรงกัน
      </small>
    </div>
    <!-- <button type="submit" class="btn btn-primary">Submit</button> -->
    <ion-button
      [disabled]="
        registerForm.invalid ||
        registerForm.get('password')?.value !==
          registerForm.get('confirmPassword')?.value
      "
      class="mt-1"
      type="submit"
      expand="block"
    >
      ลงทะเบียน
    </ion-button>
  </form>
</ion-content>
