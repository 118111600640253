import { Component, Input, OnInit } from '@angular/core';
import { ModalServiceService } from '../modal-services/modal-service.service';
import { ApiMaintenanceService } from '@/app/services/api-maintenance.service';
import { CheckAssetService } from '@/app/services/check-asset.service';
import { DatePipe } from '@angular/common';
import { LoadingService } from '@/app/services/loading.service';
@Component({
  selector: 'app-report-maintenance-modal',
  templateUrl: './report-maintenance-modal.component.html',
  styleUrls: ['./report-maintenance-modal.component.scss'],
})
export class ReportMaintenanceModalComponent implements OnInit {
  public maintenance: any;
  @Input() maintenanceId: any;


  public isAirGroup: boolean = true;
  public preMaintenance: any;
  public postMaintenance: any;
  public exeMaintenance: any;

  constructor(
    private _modal: ModalServiceService,
    private maintenanceService: ApiMaintenanceService,
    private checkAssetService: CheckAssetService,
    private _loadingService: LoadingService,

  ) { }

  ngOnInit() {

    console.log('report maintenance', this.maintenanceId);
    this.fetchData()
  }


  async fetchData() {
    this._loadingService.show()

    try {
      const results: any = await this.maintenanceService.getMaintenanceById(this.maintenanceId);
      if (results) {
        this.maintenance = results;
        this.preMaintenance = results.maintenanceDocuments.filter((e: { type: string; }) => e.type === 'PRE')[0];
        this.postMaintenance = results.maintenanceDocuments.filter((e: { type: string; }) => e.type === 'POST')[0];
        this.exeMaintenance = results.maintenanceDocuments.filter((e: { type: string; }) => e.type === 'EXE')[0];
        this.isAirGroup = this.checkAssetTypeAir();
      }
      console.log('maintenanceService', this.maintenance, this.isAirGroup);
    } catch (error) {
      console.error('fetch getMaintenance Id error', error);
      throw error;
    } finally {
      this._loadingService.hide()
    }
  }


  close() {
    this._modal.maintenanceReportClose();
  }

  pipeAssetTypeForName(type: any): string {
    if (!type) return ' - '
    const name = this.checkAssetService.pipeAssetTypeForName(type)
    return name ? name : ' - '
  }

  pipeMaintenanceTypeForName(type: string) {
    if (!type) return null
    return this.checkAssetService.pipeMaintenanceTypeForName(type)

  }

  dateTh(value: any) {
    if (!value) return '-'
    const datePipe = new DatePipe('en-TH');
    return datePipe.transform(value, 'dd/MM/yyyy HH:mm');
  }

  checkAssetTypeAir(): boolean {
    if (!this.maintenance?.asset?.group) {
      return false;
    }
    return this.checkAssetService.checkAssetTypeAir(this.maintenance?.asset?.group);
  }


}
