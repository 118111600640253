import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';

import { AuthenticationService } from '../../services/authentication.service';
import { ConfirmModalService } from 'src/app/components/modals/confirm-modal.service';

import { Logger } from 'src/app/services/logger.service';
import { RedirectService } from '@/app/services/redirect-browser.service';
import { LoadingService } from '@/app/services/loading.service';
const log = new Logger('SignInComponent')
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  public loginForm!: FormGroup;
  passwordTextType = false;
  isLoading = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _confirmModalService: ConfirmModalService,
    private _loadingService: LoadingService,

  ) { }

  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      username: ["", [Validators.required]],
      password: ["", Validators.required],
    });
  }
  // convenience getter for easy access to form fields
  get f(): any {
    return this.loginForm.controls;
  }

  onSubmit() {

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    // Login
    this._loadingService.showLong()
    this.isLoading = true;

    this._authenticationService
      .login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this._loadingService.hide()
          this.isLoading = false;
          this._router.navigate(['tabs']);
        },
        (error) => {
          log.error('error sign in', error);
          this._loadingService.hide()

          this._confirmModalService.ok('ไม่สามารถเข้าสู่ระบบได้', error.error.message)
          this.isLoading = false;
        }
      );
  }

  goRegistor() {
    this._router.navigate(['sign-up']);
  }

  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType
  }



}
