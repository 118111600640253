import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';

import { Logger } from 'src/app/services/logger.service';

const log = new Logger('HeaderComponent')
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() hideIconRight = false;
  @Input() hideIconLeft = false;
  @Input() title: string | undefined;
  @Output() onClickBack = new EventEmitter<any>()



  constructor(
    private navController: NavController,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  goBack() {
    this.onClickBack.emit();
    this.navController.back();

  }

  goScan(p = false) {
    if (!p) {
      this.router.navigate(['/tabs/tab-scan']);
    }
  }
}
