<ion-card class="card-home">
  <ion-card-header>
    <ion-card-title> {{ name }} </ion-card-title>
    <ion-card-subtitle> วันที่: {{ date }} น.</ion-card-subtitle>
  </ion-card-header>

  <ion-row>
    <ion-col>
      <div>
        <div>สถานที่: {{ place }}</div>
        <div>
          อาคาร: <span> {{ building }}</span> ชั้น: <span> {{ floor }} </span>
        </div>
        <div>ประเภท: {{ typeAsset }}</div>
      </div>
    </ion-col>
    <ion-col class="col-btn">
      <ion-button fill="outline" (click)="openReport()" > ดูรายงาน </ion-button>
      <ion-button (click)="clickBtn()"> เซ็นรับรอง </ion-button>
    </ion-col>
  </ion-row>
</ion-card>
