import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
})
export class InfoCardComponent implements OnInit {
  @Input() isToggle = false;
  @Input() textTitle: string | undefined;
  @Output() toggle1 = new EventEmitter<any>
  showDetail = true;
  constructor() { }

  ngOnInit() { }


  onClick1() {
    this.showDetail = !this.showDetail;
    this.toggle1.emit(this.showDetail);
  }
}
